<template>
  <a-drawer
    title="交易轨迹"
    placement="right"
    :width="'86vw'"
    :closable="true"
    :visible="visible"
    @close="onClose"
  >
    <div style="height: 80vh; overflow-y: scroll">
      <a-table
        @change="changePage"
        :pagination="pagination"
        :loading="loading"
        :columns="columns"
        :data-source="list"
        :rowKey="(record, index) => index"
      >
        <div slot="setup" slot-scope="row">
          <a-button style="margin: 8px" type="primary" size="small" @click="seeClick(row)"
            >查看</a-button
          >
          <a-popconfirm
            title="Are you sure delete this item?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="remove(row.id)"
          >
            <a-button size="small" type="danger">删除</a-button>
          </a-popconfirm>
        </div>
      </a-table>
    </div>
  </a-drawer>
</template>

<script>
export default {
  props: ["pcgsNumber"],
  data() {
    return {
      visible: true,
      list: [],
      loading: false,
      params: {
        pageNum: 1,
        pageSize: 20,
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showTotal: (total) => `共${total}条`,
      },
      columns: [
        {
          align: "center",
          key: "time",
          title: "成交时间",
          width: 120,
          scopedSlots: { customRender: "time", title: "time" },
          customRender: (text, row, index) => {
            return <span>{row.time}</span>;
          },
        },
        {
          align: "center",
          title: "原商品标题",
          customRender: (text, row, index) => {
            return <span style="color:#1890ff">{row.title}</span>;
          },
        },
        {
          align: "center",
          title: "来源",
          customRender: (text, row, index) => {
            switch (row.goodsSource) {
              case 1:
                return "HX";
              case 2:
                return "QBTT";
              case 3:
                return "WPT";
              case 10:
                return "SBP";
              default:
                return "XZ";
            }
          },
        },
        {
          align: "center",
          key: "action",
          title: "成交价",
          scopedSlots: { customRender: "action", title: "price" },
          customRender: (text, row, index) => {
            return <span style="color:red">{row.price}</span>;
          },
        },
        {
          align: "center",
          dataIndex: "reviewStatus",
          title: "状态",
          width: 80,
          customRender: (val, row, index) => {
            switch (val) {
              case 0: //待确认
                return <span style="color:green">待确认</span>;

              case 1: //已确认
                return <span style="color:red">已确认</span>;
            }
          },
        },
        {
          align: "center",
          title: "操作",
          scopedSlots: { customRender: "setup" },
        },
      ],
    };
  },
  created() {
    this.$set(this.params, "code", this.pcgsNumber);
    this.getList();
  },
  methods: {
    // 删除
    async remove(id) {
      this.$loading.show();
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/management/del",
        {
          params: { id: id },
        }
      );
      this.$loading.hide();
      if (res.status != 200) return;
      this.$message.success(res.message || "删除成功");
      this.getList()
    },
    seeClick(row) {
      const { pcgsNumber, reviewStatus, goodsType } = row;
      this.onClose();
      let path = "/cms/silver/confirm";
      let query = { code: pcgsNumber };
      if (this.$route.path == path) {
        this.onClose();
        this.$eventBus.$emit("searchCode", pcgsNumber);
      } else {
        const{ href } = this.$router.resolve({path,query});
        window.open(href)
        // this.$router.push({ path, query });
      }
    },
    async getList() {
      this.loading = true;
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/management/list",
        {
          params: this.params,
        }
      );
      this.loading = false;
      if (res.status != 200) return (this.list = []);
      this.list = res.data.records;
      this.pagination.total = res.data.total;
    },
    // 切换分页
    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getList();
    },
    onClose() {
      this.visible = false;
      setTimeout(() => {
        this.$emit("close");
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    height: 60px !important;
  }
}
</style>