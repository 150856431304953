var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-table",
        {
          attrs: {
            "row-selection": {
              selectedRowKeys: _vm.selectedRowKeys,
              onChange: _vm.onSelectChange
            },
            pagination: _vm.pagination,
            loading: _vm.loading,
            columns: _vm.columns,
            "data-source": _vm.list,
            rowKey: function(record, index) {
              return index
            }
          },
          on: { change: _vm.changePage },
          scopedSlots: _vm._u([
            {
              key: "pcgsNumber",
              fn: function(row) {
                return _c("div", { staticClass: "pcsg-num" }, [
                  _c("div", [_vm._v(_vm._s(row.pcgsNumber))]),
                  parseInt(row.trajectorySize) > 1
                    ? _c(
                        "div",
                        {
                          staticClass: "count",
                          on: {
                            click: function($event) {
                              return _vm.trajectoryClick(row)
                            }
                          }
                        },
                        [_vm._v("交易轨迹" + _vm._s(row.trajectorySize) + "次")]
                      )
                    : _vm._e()
                ])
              }
            },
            {
              key: "Images",
              fn: function(row) {
                return _c("div", {}, [
                  _c("img", {
                    staticStyle: { "max-width": "50px", "max-height": "50px" },
                    attrs: { src: row.coverUrl, alt: "" },
                    on: {
                      click: function($event) {
                        return _vm.previewImg(row)
                      }
                    }
                  })
                ])
              }
            },
            {
              key: "setup",
              fn: function(row) {
                return _c(
                  "div",
                  {
                    staticStyle: { display: "flex", "flex-direction": "column" }
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _vm.type == 2
                          ? _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: "Are you sure confirm this item?",
                                  "ok-text": "Yes",
                                  "cancel-text": "No"
                                },
                                on: {
                                  confirm: function($event) {
                                    return _vm.batchConfirmation(row.id, 2)
                                  }
                                }
                              },
                              [
                                _c(
                                  "a-button",
                                  {
                                    staticStyle: { "margin-right": "5px" },
                                    attrs: { type: "primary", size: "small" }
                                  },
                                  [_vm._v("非白币确认")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.type == 2
                          ? _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: "Are you sure confirm this item?",
                                  "ok-text": "Yes",
                                  "cancel-text": "No"
                                },
                                on: {
                                  confirm: function($event) {
                                    return _vm.batchConfirmation(row.id, 1)
                                  }
                                }
                              },
                              [
                                _c(
                                  "a-button",
                                  { attrs: { type: "primary", size: "small" } },
                                  [_vm._v("白币确认")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "5px", display: "flex" } },
                      [
                        _c(
                          "a-button",
                          {
                            staticStyle: { "margin-right": "5px" },
                            attrs: { size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.openModal(row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm.type == 1
                          ? _c(
                              "a-button",
                              {
                                staticStyle: { "margin-right": "5px" },
                                attrs: { size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.appreciation(row)
                                  }
                                }
                              },
                              [_vm._v("鉴赏")]
                            )
                          : _vm._e(),
                        _c(
                          "a-button",
                          {
                            staticStyle: { "margin-right": "5px" },
                            attrs: { size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.getCoverUrls(row)
                              }
                            }
                          },
                          [_vm._v("更新封面")]
                        ),
                        _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              title: "Are you sure delete this item?",
                              "ok-text": "Yes",
                              "cancel-text": "No"
                            },
                            on: {
                              confirm: function($event) {
                                return _vm.remove(row.id)
                              }
                            }
                          },
                          [
                            _c(
                              "a-button",
                              { attrs: { size: "small", type: "danger" } },
                              [_vm._v("删除")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              }
            }
          ])
        },
        [
          _c("template", { slot: "price" }, [
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _c("div", { staticStyle: { width: "50px" } }, [
                  _vm._v("成交价")
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-direction": "column",
                      "align-items": "center"
                    }
                  },
                  [
                    _c("a-icon", {
                      style: _vm.priceSort == 1 ? "color:#1890ff" : "",
                      attrs: { type: "caret-up" },
                      on: {
                        click: function($event) {
                          return _vm.priceSortClick(1)
                        }
                      }
                    }),
                    _c("a-icon", {
                      style: _vm.priceSort == -1 ? "color:#1890ff" : "",
                      attrs: { type: "caret-down" },
                      on: {
                        click: function($event) {
                          return _vm.priceSortClick(-1)
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            )
          ]),
          _c("template", { slot: "time" }, [
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _c("div", [_vm._v("成交时间")]),
                _vm.type == 2
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                          "align-items": "center"
                        }
                      },
                      [
                        _c("a-icon", {
                          style: _vm.timeSort == 2 ? "color:#1890ff" : "",
                          attrs: { type: "caret-up" },
                          on: {
                            click: function($event) {
                              return _vm.timeSortClick(2)
                            }
                          }
                        }),
                        _c("a-icon", {
                          style: _vm.timeSort == 1 ? "color:#1890ff" : "",
                          attrs: { type: "caret-down" },
                          on: {
                            click: function($event) {
                              return _vm.timeSortClick(1)
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            )
          ]),
          _c(
            "div",
            { attrs: { slot: "updateTimeTitle" }, slot: "updateTimeTitle" },
            [
              _vm._v(
                " " + _vm._s(_vm.type == 1 ? "确认时间" : "采集时间") + " "
              )
            ]
          )
        ],
        2
      ),
      _vm.pcgsNumber
        ? _c("TradeTrajectory", {
            attrs: { pcgsNumber: _vm.pcgsNumber },
            on: { close: _vm.tradeClose }
          })
        : _vm._e(),
      _c("AddAppreciation", {
        ref: "add-appreciation",
        attrs: { detail: _vm.detail },
        on: { success: _vm.appreciationSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }