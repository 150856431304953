<template>
  <div>
    <a-table
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      @change="changePage"
      :pagination="pagination"
      :loading="loading"
      :columns="columns"
      :data-source="list"
      :rowKey="(record, index) => index"
    >
      <div
        class="pcsg-num"
        slot="pcgsNumber"
        slot-scope="row"
      >
        <div>{{row.pcgsNumber}}</div>
        <div
          v-if="parseInt(row.trajectorySize) > 1"
          class="count"
          @click="trajectoryClick(row)"
        >交易轨迹{{row.trajectorySize}}次</div>
      </div>
      <template slot="price">
        <div style="display: flex; align-items: center">
          <div style="width: 50px">成交价</div>
          <div style="display: flex; flex-direction: column; align-items: center">
            <a-icon
              :style="priceSort == 1 ? 'color:#1890ff' : ''"
              @click="priceSortClick(1)"
              type="caret-up"
            />
            <a-icon
              :style="priceSort == -1 ? 'color:#1890ff' : ''"
              @click="priceSortClick(-1)"
              type="caret-down"
            />
          </div>
        </div>
      </template>
      <template slot="time">
        <div style="display: flex; align-items: center">
          <div>成交时间</div>
          <div
            style="display: flex; flex-direction: column; align-items: center"
            v-if="type == 2"
          >
            <a-icon
              :style="timeSort == 2 ? 'color:#1890ff' : ''"
              @click="timeSortClick(2)"
              type="caret-up"
            />
            <a-icon
              :style="timeSort == 1 ? 'color:#1890ff' : ''"
              @click="timeSortClick(1)"
              type="caret-down"
            />
          </div>
        </div>
      </template>
      <div
        slot="Images"
        slot-scope="row"
      >
        <img
          @click="previewImg(row)"
          style="max-width: 50px; max-height: 50px"
          :src="row.coverUrl"
          alt=""
        />
      </div>
      <div slot="updateTimeTitle">
        {{type == 1 ? '确认时间' : '采集时间'}}
      </div>
      <div
        slot="setup"
        slot-scope="row"
        style="display: flex; flex-direction: column"
      >
        <div style="display: flex">
          <a-popconfirm
            title="Are you sure confirm this item?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="batchConfirmation(row.id, 2)"
            v-if="type == 2"
          >
            <a-button
              style="margin-right: 5px"
              type="primary"
              size="small"
            >非白币确认</a-button>
          </a-popconfirm>
          <a-popconfirm
            title="Are you sure confirm this item?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="batchConfirmation(row.id, 1)"
            v-if="type == 2"
          >
            <a-button
              type="primary"
              size="small"
            >白币确认</a-button>
          </a-popconfirm>
        </div>

        <div style="margin-top: 5px; display: flex">
          <a-button
            size="small"
            @click="openModal(row)"
            style="margin-right: 5px"
          >编辑</a-button>
          <a-button
            size="small"
            v-if="type == 1"
            @click="appreciation(row)"
            style="margin-right: 5px"
          >鉴赏</a-button>
          <a-button
            size="small"
            @click="getCoverUrls(row)"
            style="margin-right: 5px"
          >更新封面</a-button>
          <a-popconfirm
            title="Are you sure delete this item?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="remove(row.id)"
          >
            <a-button
              size="small"
              type="danger"
            >删除</a-button>
          </a-popconfirm>
        </div>
      </div>
    </a-table>

    <!-- 交易轨迹 -->
    <TradeTrajectory
      v-if="pcgsNumber"
      :pcgsNumber="pcgsNumber"
      @close="tradeClose"
    ></TradeTrajectory>
    <!-- 赏析 -->
    <AddAppreciation ref="add-appreciation" :detail="detail" @success="appreciationSuccess"></AddAppreciation>
  </div>
</template>

<script>
import TradeTrajectory from "@/views/cmsPage/dollarManager/_components/TradeTrajectory.vue";
import AddAppreciation from "@/views/cmsPage/dollarManager/_components/appreciation.vue"
export default {
  props: ["type", "loading", "pagination", "list"],
  data() {
    return {
      pcgsNumber: "",
      selectedRowKeys: [],
      priceSort: 0,
      timeSort: 0,
      detail: "",
      columns: [
        {
          title: "id",
          dataIndex: "id",
          align: "center",
        },
        {
          align: "center",
          title: "编号",
          scopedSlots: { customRender: "pcgsNumber" },
        },
        {
          align: "center",
          title: "原商品标题",
          customRender: (text, row, index) => {
            return <span style="color:#1890ff">{row.title}</span>;
          },
        },
        {
          align: "center",
          title: "封面图",
          scopedSlots: { customRender: "Images" },
        },
        {
          align: "center",
          title: "大币种-版别",
          dataIndex: "versionName",
        },
        {
          align: "center",
          title: "来源",
          customRender: (text, row, index) => {
            switch (row.goodsSource) {
              case 1:
                return "HX";
              case 2:
                return "QBTT";
              case 3:
                return "WPT";
              case 10:
                return "SBP";
              default:
                return "XZ";
            }
          },
        },
        {
          align: "center",
          key: "time",
          scopedSlots: { customRender: "time", title: "time" },
          customRender: (text, row, index) => {
            return <span style="color: #32CD32;">{row.time}</span>;
          },
        },
        {
          align: "center",
          title: "评级",
          dataIndex: "grade",
        },
        {
          align: "center",
          title: "分数",
          dataIndex: "score",
        },
        {
          align: "center",
          key: "action",
          scopedSlots: { customRender: "action", title: "price" },
          customRender: (text, row, index) => {
            return <span style="color:red">{row.price}</span>;
          },
        },
        {
          title: "对比",
          align: "center",
          customRender: (text, row, index) => {
            return <span style="color:red">{row.coinWhite}</span>;
          },
        },
        {
          align: "center",
          dataIndex: "updateTime",
          scopedSlots: { customRender: "updateTime", title: "updateTimeTitle" },
          customRender: (text, row, index) => {
            return (
              <span style="color: #32CD32;">
                {row.reviewStatus == 1 || this.type == 2 ? row.updateTime : "-"}
              </span>
            );
          },
        },
        {
          align: "center",
          title: "操作",
          scopedSlots: { customRender: "setup" },
        },
      ],
    };
  },
  watch: {
    loading() {
      this.selectedRowKeys = [];
      this.$emit("selectChange", []);
    },
  },
  components: {
    TradeTrajectory,
    AddAppreciation
  },
  methods: {
    // 赏析成功
    appreciationSuccess(){
      this.$emit("success");
    },
    // 鉴赏
    appreciation(row){
      this.detail = JSON.parse(JSON.stringify(row))
      this.$refs["add-appreciation"].show = true
    },
    tradeClose() {
      this.pcgsNumber = "";
    },
    trajectoryClick(row) {
      this.pcgsNumber = row.pcgsNumber;
    },
    // 编辑
    openModal(row) {
      this.$emit("edit", row);
    },
    // 确认白币非白币
    async batchConfirmation(id, type) {
      id = id.toString();
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/batchConfirmation",
        {
          params: {
            silverGoodsIds: id,
            type: type,
          },
        }
      );
      if (res.status != 200) return;
      this.$message.success(res.message || "请求成功");
      this.$emit("success");
    },
    // 删除
    async remove(id) {
      this.$loading.show();
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/management/del",
        {
          params: { id: id },
        }
      );
      this.$loading.hide();
      if (res.status != 200) return;
      this.$message.success(res.message || "删除成功");
      this.$emit("delete", id);
    },
    // 更新封面
    async getCoverUrls(row) {
      this.$loading.show();
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/editCover",
        { params: { goodsId: row.id } }
      );
      this.$loading.hide();
      if (res.status != 200) return;
      this.$message.success(res.message || "获取成功");
      this.$emit("success");
    },
    priceSortClick(val) {
      if (this.priceSort == val) return;
      this.priceSort = val;
      this.timeSort = null;
      this.$emit("priceSortChange", val);
    },
    timeSortClick(val) {
      if (this.timeSort == val) return;
      this.timeSort = val;
      this.priceSort = null;
      this.$emit("timeSortChange", val);
    },
    changePage(page) {
      this.$emit("changePage", page);
    },
    onSelectChange(keys, arr) {
      this.selectedRowKeys = keys;
      this.$emit("selectChange", arr);
    },
    previewImg(row) {
      if (row.images && row.images.length > 0) {
        this.$previewImg({
          list: row.images.reduce((pre, cur) => {
            const obj = { img_url: cur };
            pre = pre.concat(obj);
            return pre;
          }, []),

          baseImgField: "img_url",
          showMute: false,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  height: 100px;
}
.pcsg-num {
  .count {
    color: chocolate;
    cursor: pointer;
  }
}
</style>