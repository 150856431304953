var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: "交易轨迹",
        placement: "right",
        width: "86vw",
        closable: true,
        visible: _vm.visible
      },
      on: { close: _vm.onClose }
    },
    [
      _c(
        "div",
        { staticStyle: { height: "80vh", "overflow-y": "scroll" } },
        [
          _c("a-table", {
            attrs: {
              pagination: _vm.pagination,
              loading: _vm.loading,
              columns: _vm.columns,
              "data-source": _vm.list,
              rowKey: function(record, index) {
                return index
              }
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "setup",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { margin: "8px" },
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.seeClick(row)
                            }
                          }
                        },
                        [_vm._v("查看")]
                      ),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "Are you sure delete this item?",
                            "ok-text": "Yes",
                            "cancel-text": "No"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.remove(row.id)
                            }
                          }
                        },
                        [
                          _c(
                            "a-button",
                            { attrs: { size: "small", type: "danger" } },
                            [_vm._v("删除")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }