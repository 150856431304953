var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          directives: [{ name: "drag-modal", rawName: "v-drag-modal" }],
          attrs: {
            title: "编辑商品",
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.cancelForm, ok: _vm.hideModal },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "ruleForm",
                attrs: { model: _vm.forms, rules: _vm.rules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "ID" } },
                [
                  _c("a-input", {
                    attrs: { disabled: "", size: "small" },
                    model: {
                      value: _vm.forms.id,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "id", $$v)
                      },
                      expression: "forms.id"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "标题" } },
                [
                  _c("a-input", {
                    attrs: { size: "small" },
                    model: {
                      value: _vm.forms.title,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "title", $$v)
                      },
                      expression: "forms.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "大币种", prop: "kindId" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        allowClear: "",
                        showSearch: "",
                        size: "small",
                        placeholder: "请选择大币种"
                      },
                      on: { change: _vm.changeVersionlist },
                      model: {
                        value: _vm.forms.kindId,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "kindId", $$v)
                        },
                        expression: "forms.kindId"
                      }
                    },
                    _vm._l(_vm.bigList, function(items) {
                      return _c(
                        "a-select-option",
                        { key: items.kindId, attrs: { value: items.kindId } },
                        [_vm._v(_vm._s(items.kindName))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "版别", prop: "versionId" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        allowClear: "",
                        showSearch: "",
                        "filter-option": _vm.untils.filterOption,
                        size: "small",
                        placeholder: "请选择版别"
                      },
                      on: {
                        select: _vm.selectVersion,
                        search: _vm.getVersitions
                      },
                      model: {
                        value: _vm.forms.versionId,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "versionId", $$v)
                        },
                        expression: "forms.versionId"
                      }
                    },
                    _vm._l(_vm.versions, function(items) {
                      return _c(
                        "a-select-option",
                        {
                          key: items.versionId,
                          attrs: { value: items.versionId }
                        },
                        [_vm._v(_vm._s(items.versionName))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "编号" } },
                [
                  _c("a-input", {
                    attrs: { size: "small" },
                    model: {
                      value: _vm.forms.pcgsNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "pcgsNumber", $$v)
                      },
                      expression: "forms.pcgsNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "分数", prop: "scoreId" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { size: "small", placeholder: "请选择评分" },
                      model: {
                        value: _vm.forms.scoreId,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "scoreId", $$v)
                        },
                        expression: "forms.scoreId"
                      }
                    },
                    _vm._l(_vm.ranks, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.id } },
                        [_vm._v(_vm._s(item.scoreName))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "价格" } },
                [
                  _c("a-input", {
                    attrs: {
                      allowClear: "",
                      size: "small",
                      placeholder: "请输入价格"
                    },
                    model: {
                      value: _vm.forms.price,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "price", $$v)
                      },
                      expression: "forms.price"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "白币" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { size: "small", placeholder: "请选择" },
                      model: {
                        value: _vm.forms.whiteCoinStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "whiteCoinStatus", $$v)
                        },
                        expression: "forms.whiteCoinStatus"
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v("是")
                      ]),
                      _c("a-select-option", { attrs: { value: 0 } }, [
                        _vm._v("否")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "封面图" } },
                [
                  _c(
                    "a-upload",
                    {
                      attrs: {
                        action: _vm.ip + "/file/upload",
                        "list-type": "picture-card",
                        "file-list": _vm.fileList
                      },
                      on: {
                        preview: _vm.handlePreview,
                        change: _vm.handleChange
                      }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("a-icon", { attrs: { type: "plus" } }),
                          _c("div", { staticClass: "ant-upload-text" }, [
                            _vm._v("上传图片")
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.previewVisible, footer: null },
          on: {
            cancel: function($event) {
              _vm.previewVisible = false
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { alt: "example", src: _vm.previewImage }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }