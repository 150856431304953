<template>
  <div>
    <a-modal
      v-drag-modal
      v-model="visible"
      title="编辑商品"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancelForm"
      @ok="hideModal"
    >
      <a-form-model
        ref="ruleForm"
        :model="forms"
        :rules="rules"
        v-bind="layout"
      >
        <a-form-model-item label="ID">
          <a-input
            disabled
            size="small"
            v-model="forms.id"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="标题">
          <a-input
            size="small"
            v-model="forms.title"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item
          label="大币种"
          prop="kindId"
        >
          <a-select
            @change="changeVersionlist"
            allowClear
            showSearch
            size="small"
            v-model="forms.kindId"
            placeholder="请选择大币种"
          >
            <a-select-option
              v-for="items of bigList"
              :key="items.kindId"
              :value="items.kindId"
            >{{ items.kindName }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="版别"
          prop="versionId"
        >
          <a-select
            @select="selectVersion"
            allowClear
            @search="getVersitions"
            showSearch
            :filter-option="untils.filterOption"
            size="small"
            v-model="forms.versionId"
            placeholder="请选择版别"
          >
            <a-select-option
              v-for="items of versions"
              :key="items.versionId"
              :value="items.versionId"
            >{{ items.versionName }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="编号">
          <a-input
            size="small"
            v-model="forms.pcgsNumber"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item
          label="分数"
          prop="scoreId"
        >
          <a-select
            size="small"
            v-model="forms.scoreId"
            placeholder="请选择评分"
          >
            <a-select-option
              v-for="item of ranks"
              :key="item.id"
              :value="item.id"
            >{{ item.scoreName }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="价格">
          <a-input
            allowClear
            size="small"
            placeholder="请输入价格"
            v-model="forms.price"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="白币">
          <a-select
            size="small"
            v-model="forms.whiteCoinStatus"
            placeholder="请选择"
          >
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="封面图">
          <a-upload
            :action="ip + '/file/upload'"
            list-type="picture-card"
            :file-list="fileList"
            @preview="handlePreview"
            @change="handleChange"
          >
            <div>
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      :visible="previewVisible"
      :footer="null"
      @cancel="previewVisible = false"
    >
      <img
        alt="example"
        style="width: 100%"
        :src="previewImage"
      />
    </a-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["detail","type"],
  data() {
    return {
      visible: true,
      previewVisible: false,
      forms: {},
      fileList: [],
      rules: {
        kindId: [
          { required: true, message: "请选择大币种", trigger: "change" },
        ],
        versionId: [
          { required: true, message: "请选择版别", trigger: "change" },
        ],
        scoreId: [{ required: true, message: "请选择分数", trigger: "change" }],
      },
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      bigList: [],
      versions: [],
      ranks: [],
      previewImage: "",
    };
  },
  computed: {
    ...mapState(["ip"]),
  },
  created() {
    delete this.detail.coinWhite
    this.forms = JSON.parse(JSON.stringify(this.detail));
    this.getBigList();
    this.getVersitions();
    this.getScore();
    if (this.forms.images && this.forms.images.length > 0) {
      this.fileList = this.forms.images.map((items, i) => {
        const obj = {
          uid: i,
          name: "image",
          status: "done",
          url: items,
        };
        return obj;
      });
    }
  },
  methods: {
    // 选择大币种
    changeVersionlist() {
      this.getVersitions();
    },
    // 选择版别
    selectVersion(id) {
      delete this.forms.scoreId;
      this.getScore();
    },
    async getScore() {
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/getScreenScoreList",
        {
          params: { versionId: this.forms.versionId },
        }
      );
      if (res.status != 200) return;

      this.ranks = res.data;
    },
    async getVersitions() {
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/getSliverVersion",
        {
          params: { kindId: this.forms.kindId },
        }
      );
      if (res.status != 200) return;
      this.versions = res.data;
    },
    // 获取大币种列表
    async getBigList() {
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/getSliverKind"
      );
      if (res.status != 200) return;
      this.bigList = res.data;
    },
    hideModal() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submit(this.forms);
        } else {
          return false;
        }
      });
    },
    async submit(forms, type) {
      let params = JSON.parse(JSON.stringify(forms));
      try {
        const images = this.fileList.map((row) => {
          return row.url;
        });
        params.coverUrl = images.join(",");
      } catch (e) {}
      if (type == 2) {
        params = {
          id: forms.id,
          whiteCoinStatus: 0,
          type: 1,
        };
      }
      this.$loading.show();
      const res = await this.axios.post(
        "/dq_admin/coinSilverVersionGoods/management/edit",
        params
      );
      this.$loading.hide();
      if (res.status == 200) {
        this.$message.success(res.message || "编辑成功");
        this.visible = false;
        this.fileList = [];
        // if(this.navagitorEdit){

        //     window.postMessage({
        //         msg:'refsh',
        //         key:'dq_charts_reload'
        //     },'*')
        // }
        this.$emit("success");
        this.$emit("close");
      }
    },
    handleChange({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.fileList = fileList;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    cancelForm() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>